import PlayerTable from "../components/playerTable.jsx";

function Players() {
    return (
        <div>
            <PlayerTable></PlayerTable>
        </div>
    )
}

export default Players;